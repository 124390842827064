<template>
  <section class="py-8 bg-white">
    <div class="container px-4 mx-auto">
      <button disabled class="mb-4 w-full py-4 bg-red-600 rounded text-sm font-bold text-gray-50">ACHTUNG: Wenn bereits eine Datei mit dem gleichen Namen vorhanden ist, wird diese <b>OHNE WARNUNG ÜBERSCHRIEBEN!!!</b></button>
      <form ref="uploadForm" enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
        <div class="dropbox">
          <input type="file" :disabled="isSaving" @change="filesChange($event.target.files)"
            class="input-file">
            <p v-if="isInitial">
              Drag your file here to begin<br> or click to browse
            </p>
            <p v-if="isSaving">
              Uploading file... ({{ percentLoaded }} %)
            </p>
        </div>
      </form>
      <button v-if="uploadSuccessful === true" disabled class="mb-4 w-full py-4 bg-green-600 rounded text-sm font-bold text-gray-50">Die Datei wurde erfolgreich hochgeladen</button>
      <button v-if="uploadSuccessful === false" disabled class="mb-4 w-full py-4 bg-red-600 rounded text-sm font-bold text-gray-50">Beim Upload ist leider etwas schiefgelaufen</button>
      <div class="overflow-x-auto mb-16">
        <div class="relative inline-block min-w-full border border-gray-100 rounded-5xl overflow-hidden">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr class="text-sm uppercase font-heading text-left">
                <th class="flex items-center pl-10 py-6 pr-12 md:pr-40 lg:pr-52 2xl:pr-64">
                  <span>Filename</span>
                </th>
                <th class="py-4 pr-24">Änderungsdatum</th>
                <th class="py-4 pr-24"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in files" :key="file.name">
                <td class="p-0">
                  <div class="flex items-center pl-10 pr-4 h-20 border-b border-gray-100">
                    <div class="flex">
                      <div>
                        <p class="font-heading font-medium">{{ file.name }}</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="p-0">
                  <div class="flex items-center pr-4 h-20 border-b border-gray-100">
                    <div class="flex">
                      <div>
                        <p class="font-heading font-medium">{{ $dayjs(file.lastModified).format('LLLL') }}</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="p-0">
                  <div class="flex items-center pl-10 pr-4 h-20 border-b border-gray-100">
                    <div class="flex">
                      <div>
                        <button @click="deleteFile(file.name)" class="font-heading font-medium">Löschen</button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from 'axios';
  //import copy from 'copy-to-clipboard';

  export default {
    name: 'Applications',
    data() {
      return {
        uploadSuccessful: undefined,

        isInitial: true,
        isSaving: false,

        files: undefined,
        file: undefined,
        uploadURL: undefined,
        percentLoaded: 0,

        users: null,
        applications: undefined,
        addUserUsername: undefined,
        addUserPassword: undefined,
        addUserEmail: undefined,
        addUserAdminChecked: false,
        addUserCheckedApplications: [],
        addUserSuccessMessage: undefined,
        addUserShowSuccess: false,
        addUserErrorMessage: undefined,
        addUserShowError: false,

        userToEdit: undefined,
        showEditUserModal: false,
        editUserUsername: undefined,
        editUserPassword: undefined,
        editUserEmail: undefined,
        editUserAdminChecked: false,
        editUserCheckedApplications: [],
        editUserSuccessMessage: undefined,
        editUserShowSuccess: false,
        editUserErrorMessage: undefined,
        editUserShowError: false,

      }
    },
    mounted() {
      this.getFiles()

    },
    methods: {
        async filesChange(fileList) {
          console.log('asfasdf')
          try {
          this.uploadSuccessful = undefined
          this.isSaving = true
          this.isInitial = false

          const file = fileList[0]
            const getPresignedUrlResponse = await axios({
              method: 'POST',
              url: `${process.env.VUE_APP_BACKEND_URL}/files/presignedUrl/`,
              data: {
                fileName: file.name,
                fileType: file.type
              }
            })
            const uploadUrl = getPresignedUrlResponse.data.uploadURL

          var instance = axios.create();
          delete instance.defaults.headers.common;

          const uploadFileToS3Response = await instance.put(uploadUrl, file, {headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: progressEvent => this.percentLoaded = Math.round((progressEvent.loaded * 100) / progressEvent.total) });
          console.log(uploadFileToS3Response)
          if (uploadFileToS3Response.status === 200) {
            this.uploadSuccessful = true
          } else {
            this.uploadSuccessful = false
          }

          this.getFiles()

          this.isSaving = false
          this.isInitial = true
          this.$refs.uploadForm.reset()
          } catch (error) {
            this.uploadSuccessful = false
            this.isSaving = false
            this.isInitial = true
            this.$refs.uploadForm.reset()
            console.error(error)
          }
        },
        async deleteFile(fileName) {
          console.log(fileName)
          await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/files/${fileName}`)
          this.getFiles()
        },
      openEditUserModal(userId) {
        const user = this.users.find(element => element._id == userId);

        this.editUserUsername = user.username
        this.editUserPassword = 'XXXXXXXXXXXXXX'
        this.editUserEmail = user.email
        this.editUserAdminChecked = user.admin
        this.editUserCheckedApplications = JSON.parse(JSON.stringify(user.applications))
        console.log('asdfasdf')
        console.log(user.applications)

        this.userToEdit = userId
        this.showEditUserModal = true
      },
      getFiles() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/files/`)
        .then(response => {
          this.files = response.data
        })
        .catch(error => {
          console.log('error')
          console.log(error)

        })
      },
      async createUser() {
        axios
          .post(`${process.env.VUE_APP_BACKEND_URL}/users/`, {
            "username": this.addUserUsername,
            "password": this.addUserPassword,
            "email": this.addUserEmail,
            "applications": this.addUserCheckedApplications,
            "admin": this.addUserAdminChecked,
          })
          .then(() => {
            this.addUserShowError = false
            this.addUserErrorMessage = false

            this.addUserSuccessMessage = 'User successfully added'
            this.addUserShowSuccess = true

            this.addUserUsername = undefined
            this.addUserPassword = undefined
            this.addUserEmail = undefined
            this.adduserCheckedApplications = undefined
            this.addUserAdminChecked = false

            this.getUsers()
          })
          .catch(error => {
            this.addUserErrorMessage = error.response.data.message
            this.addUserShowError = true
            console.log(error.response)
          })
      },

      async editUser() {
        axios
          .put(`${process.env.VUE_APP_BACKEND_URL}/users/${this.userToEdit}`, {
            "username": this.editUserUsername,
            "password": this.editUserPassword,
            "email": this.editUserEmail || undefined,
            "applications": this.editUserCheckedApplications,
            "admin": this.editUserAdminChecked,
          })
          .then(() => {
            this.editUserShowError = false
            this.editUserErrorMessage = false

            this.editUserSuccessMessage = 'User successfully edited'
            this.editUserShowSuccess = true

            this.editUserUsername = undefined
            this.editUserPassword = undefined
            this.editUserEmail = undefined
            this.editUserCheckedApplications = undefined
            this.editUserAdminChecked = false

            this.getUsers()
          })
          .catch(error => {
            this.editUserErrorMessage = error.response.data.message
            this.editUserShowError = true
            console.log(error.response)
          })
      },

      getApplicationNameById(applicationId) {
        let application = "Error"
        try {
          application = (this.applications.find(element => element._id == applicationId)).name
        } catch (error) {
          console.log(error)
        }
        

        return application
      }
    },
  }
</script>

<style scoped>
  ::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep .modal-content {
    position: relative;
    width: fit-content;
    min-width: 30%;
    padding: 16px;
    overflow: auto;
    background-color: #f9fafb;
    border-radius: 4px;
  }

  .modal__title {
    font-size: 1.5rem;
    font-weight: 700;
  }
</style>

<style scoped>
  .dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
  }
</style>

<style lang="scss">
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>